<template>
  <div :class="$style.box">
    <div :class="$style.search">
      <BjSelect
        v-if="current === 'poc'"
        v-model="search.relation_group_id"
        :class="$style.input"
        key-field="id"
        placeholder="请选择文化点分组"
        value-field="id"
        label-field="name"
        show-all
        :options="pocArr"
        @change="onChange"
      />
      <BjSelect
        v-if="current === 'content'"
        v-model="search.relation_group_id"
        :class="$style.input"
        key-field="id"
        placeholder="请选择文化内容分组"
        value-field="id"
        label-field="name"
        show-all
        :options="contentArr"
        @change="onChange"
      />
      <BjSelect
        v-if="!typeShow.includes(current)"
        v-model="search.relation_type"
        :class="$style.input"
        key-field="id"
        class="mr-10"
        placeholder="请选择类型"
        value-field="id"
        label-field="name"
        show-all
        :options="typeArr"
        @change="onChange"
      />
      <BjSelect
        v-if="!typeShow.includes(current)"
        v-model="search.scene_id"
        :class="$style.input"
        key-field="id"
        placeholder="请选择场景"
        value-field="id"
        label-field="name"
        show-all
        :options="searchGroupArr"
        @change="onChange"
      />
      <div class="pull-right">
        <BjInput
          v-model="search.name"
          class="mr-10"
          :class="$style.input"
          placeholder="请输入文化码名称"
          @pressEnter="onChange()"
        >
          <i slot="suffix" :class="$style.icon" class="ri-search-2-line" @click="onChange()" />
        </BjInput>
        <BjSelect
          v-model="search.type"
          :class="$style.input"
          class="mr-10"
          key-field="name"
          placeholder="请选择"
          value-field="id"
          label-field="name"
          show-all
          :options="typeData"
          @change="onTypeChange"
        />
        <a-dropdown placement="bottomRight">
          <BjButton v-permission="'trace_source.entrance.manage-download'" type="primary">
            下载资源
            <i class="ri-arrow-down-s-line right" />
          </BjButton>
          <a-menu slot="overlay" class="list-drop">
            <a-menu-item>
              <BjLink @click="onLoadSelect()">
                <i class="ri-qr-code-line" />
                下载选中文化码
              </BjLink>
            </a-menu-item>
            <a-menu-item>
              <BjLink @click="onloadPage()">
                <i class="ri-qr-code-line" />
                下载本页文化码
              </BjLink>
            </a-menu-item>
            <!-- <a-menu-item>
              <BjLink>
                <i class="ri-link" />
                导出选中链接
              </BjLink>
            </a-menu-item>
            <a-menu-item>
              <BjLink>
                <i class="ri-link" />
                导出所有链接
              </BjLink>
            </a-menu-item> -->
          </a-menu>
        </a-dropdown>
        <BjButton
          v-if="!typeShow.includes(current)"
          v-permission="'trace_source.entrance.manage-create'"
          type="primary"
          class="ml-10"
          @click="onAdd()"
        >
          <i class="ri-add-line left" />
          新建入口
        </BjButton>
      </div>
    </div>
    <div :class="$style.view">
      <BjBox v-if="typeShow.includes(current) && release" ref="box" :scale="1" :lose="220" :deal-height="false">
        <div v-for="item in data" :key="item.id" :class="$style.item">
          <div :class="$style.cover">
            <img :src="item.qr_code[search.type]" alt="" />
            <div :class="$style.select">
              <a-checkbox :checked="selectedRowKeys.indexOf(item.id) !== -1" @change="selectChagne($event, item)" />
            </div>
          </div>
          <div :class="$style.btn">
            <p class="ellipsis-width" :class="$style.name">{{ item.name }}</p>
            <div :class="$style.btns">
              <a-tooltip>
                <template slot="title"> 文化链接 </template>
                <i v-permission="'trace_source.entrance.manage-link'" class="ri-link" @click="onCopy(item)" />
              </a-tooltip>
              <a-tooltip>
                <template slot="title"> 下载文化码 </template>
                <i
                  v-permission="'trace_source.entrance.manage-download'"
                  class="ri-download-line"
                  @click="onloadPage(item)"
                />
              </a-tooltip>
              <a-tooltip>
                <template slot="title"> 放入自定义分组 </template>
                <i
                  v-permission="'trace_source.entrance.manage-move'"
                  class="ri-folder-3-line"
                  @click="onAddEnter(item)"
                />
              </a-tooltip>
            </div>
          </div>
        </div>
      </BjBox>
      <BjBox v-if="!typeShow.includes(current) && release" ref="box" :scale="1" :lose="220" :deal-height="false">
        <!-- <div :class="$style.item">
          <i class="ri-add-line" />
          <P>新建入口</P>
        </div> -->
        <div v-for="item in data" :key="item.id" :class="$style.item">
          <div :class="$style.cover">
            <img :src="item.qr_code[search.type]" alt="" />
            <div :class="$style.select">
              <a-checkbox :checked="selectedRowKeys.indexOf(item.id) !== -1" @change="selectChagne($event, item)" />
            </div>
          </div>
          <div :class="$style.btn">
            <p class="ellipsis-width" :class="$style.title">{{ item.name }}</p>
            <p class="ellipsis-width" :class="$style.relation">关联：{{ item.content.label }}</p>
            <p :class="$style.num">
              <span class="ellipsis-width">编号：{{ item.number }}</span>
              <span class="ellipsis-width" :class="$style.tag">{{ item.scene }}</span>
            </p>
            <div :class="$style.btns">
              <a-tooltip>
                <template slot="title"> 编辑 </template>
                <i class="ri-edit-2-line" @click="onEdit(item)" />
              </a-tooltip>
              <a-tooltip>
                <template slot="title"> 下载文化码 </template>
                <i class="ri-download-line" @click="onloadPage(item)" />
              </a-tooltip>
              <a-tooltip>
                <template slot="title"> 移动分组 </template>
                <i class="ri-arrow-left-right-line" @click="onMove(item)" />
              </a-tooltip>
              <a-tooltip>
                <template slot="title"> 删除 </template>
                <a-popconfirm title="确定要删除吗？" @confirm="onDelete(item)">
                  <i :class="$style.error" class="ri-delete-bin-7-line" />
                </a-popconfirm>
              </a-tooltip>
            </div>
          </div>
        </div>
      </BjBox>
      <div v-if="!data.length && release" class="no-data">
        <div class="nodata">
          <img :src="oss + '/manage/media/state/no_comment.png'" alt="" />
          <p>暂无数据</p>
        </div>
      </div>
      <div v-if="!release" class="no-data">
        <div class="nodata">
          <img :src="oss + '/manage/media/state/no_data.png'" alt="" />
          <p>微信小程序未提交版本，暂时无法生成</p>
        </div>
      </div>
    </div>
    <div class="pagination">
      <a-pagination
        :current="page"
        show-size-changer
        :show-total="total => `共 ${total} 条`"
        :page-size.sync="pageSize"
        :total="total"
        @showSizeChange="onShowSizeChange"
        @change="onPageChange"
      />
    </div>

    <bj-modal
      :title="title"
      :visible="visible.add"
      :width="520"
      :body-style="{ height: '450px' }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <BjForm :class="$style.modal">
        <a-row v-if="typeShow.includes(current)" class="mb-20">
          <a-col class="line-40" span="5">
            <span class="required">*</span>
            选择分组
          </a-col>
          <a-col span="19" class="tip">
            <BjSelect
              v-model="add.group_id"
              key-field="id"
              placeholder="请选择分组"
              value-field="id"
              label-field="name"
              show-all
              :options="groupData"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col class="line-40" span="5">
            <span class="required">*</span>
            入口名称
          </a-col>
          <a-col span="19" class="tip">
            <BjInput v-model="add.name" placeholder="请输入入口名称" />
          </a-col>
        </a-row>
        <a-row class="mt-10 mb-20">
          <a-col span="5" />
          <a-col span="19" class="tip"> 入口名称用于标识使用，可以用“关联内容+使用场景”的形式命名。 </a-col>
        </a-row>
        <a-row>
          <a-col class="line-40" span="5">
            <span class="required">*</span>
            关联内容
          </a-col>
          <a-col span="19">
            <BjButton v-if="!typeShow.includes(current)" class="btn-default" @click="onAddContent()">
              <i class="ri-link left" />
              选择关联
            </BjButton>
            <p v-if="add.content" class="mb-20 mt-10">
              关联： <span class="primary">{{ add.content.label }}</span>
            </p>
            <p v-else class="tip mt-10">请选择入口需要关联的内容。</p>
          </a-col>
        </a-row>
        <a-row>
          <a-col class="line-40" span="5"> 选择场景 </a-col>
          <a-col span="19">
            <BjSelect
              v-model="add.scene_id"
              key-field="id"
              placeholder="请选择场景"
              value-field="id"
              label-field="name"
              show-all
              :options="groupArr"
            />
          </a-col>
        </a-row>
        <a-row class="mt-10 mb-20">
          <a-col span="5" />
          <a-col span="19" class="tip"> 请选择入口文化码使用的场景，也便于后续统计渠道来源。 </a-col>
        </a-row>
        <a-row>
          <a-col class="line-40" span="5">描述信息</a-col>
          <a-col span="19">
            <a-textarea
              v-model="add.describe"
              placeholder="请输入此入口的描述信息。"
              :auto-size="{ minRows: 3, maxRows: 3 }"
            />
          </a-col>
        </a-row>
      </BjForm>
    </bj-modal>
    <bj-modal
      title="文化链接"
      :visible="visible.copy"
      :width="520"
      :body-style="{ height: '400px' }"
      @ok="visible.copy = false"
      @cancel="visible.copy = false"
    >
      <BjForm :class="$style.modal">
        <a-row>
          <a-col class="line-40" span="5"> 文化链接 </a-col>
          <a-col span="19" class="tip">
            <BjInput id="copy" v-model="copy.link" class="map" read-only placeholder="文化链接">
              <BjButton slot="suffix" :class="$style.copy" size="small" type="primary" @click="onCopyLink()">
                <i class="ri-file-copy-line" />
                复制链接
              </BjButton>
            </BjInput>
            <p class="tip mt-10">直接访问文化链接，即可进入对应的页面。</p>
          </a-col>
        </a-row>
      </BjForm>
    </bj-modal>
    <bj-modal
      title="移动分组"
      :visible="visible.move"
      :width="520"
      :body-style="{ height: '400px' }"
      @ok="onMoveOk"
      @cancel="visible.move = false"
    >
      <BjForm :class="$style.modal">
        <a-row>
          <a-col class="line-40" span="5">
            <span class="required">*</span>
            选择分组
          </a-col>
          <a-col span="19" class="tip">
            <BjSelect
              v-model="move.group_id"
              key-field="id"
              placeholder="请选择分组"
              value-field="id"
              label-field="name"
              show-all
              :options="groupData"
            />
          </a-col>
        </a-row>
      </BjForm>
    </bj-modal>
    <chooseLink :visible.sync="visible.choose" :data="add.content" :is-link="false" @change="contentChange" />
  </div>
</template>

<script>
import chooseLink from '@/components/chooseLink.vue'
import { entranceService, sceneService, systemService } from '@/service'

const service = new entranceService()
const sService = new sceneService()
const sysService = new systemService()

export default {
  name: 'Home',
  components: { chooseLink },
  props: {
    current: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: {
        name: null,
        type: 'general',
        relation_group_id: '',
        relation_type: '',
        scene_id: '',
      },
      visible: {
        add: false,
        choose: false,
        link: false,
        copy: false,
        move: false,
      },
      title: '新建入口',
      data: [],
      id: null,
      add: {
        name: null,
        content: null,
        group_id: [],
        scene_id: [],
        describe: null,
      },
      copy: {
        link: null,
      },
      move: {
        id: null,
        group_id: null,
      },
      page: 1,
      pageSize: 20,
      total: 0,
      pocArr: [],
      contentArr: [],
      groupArr: [],
      searchGroupArr: [],
      typeArr: [],
      selectedRowKeys: [],
      groupData: [],
      typeShow: ['poc', 'content', 'terminal_module', 'template_page'],
      typeData: [
        {
          name: '多端统一码',
          id: 'general',
        },
        {
          name: '微信小程序码',
          id: 'wx',
        },
        // {
        //   name: '百度小程序码',
        //   id: 'baidu',
        // },
        // {
        //   name: '字节跳动小程序码',
        //   id: 'tt',
        // },
      ],
      showData: [], // 存的是多端下拉是否有的数据
      release: true,
    }
  },
  watch: {
    current: {
      handler() {
        this.search.relation_group_id = ''
        this.search.scene_id = ''
        this.search.relation_type = ''
        this.selectedRowKeys = []
        this.search.type = 'general'
        this.page = 1
        this.search.name = null
        this.getList()
      },
      immediate: true,
    },
  },
  async created() {
    this.getPoc() // poc 分组
    this.getContent() // 内容分组
    this.getGroup() // 场景分组
    this.getLeft() // 关联类型
    // 左边的分组
    this.getLeftGroup()
    this.getShow()
  },
  methods: {
    async getLeftGroup() {
      const { data } = await service.groupList()
      this.groupData = data.group
    },
    onChange() {
      this.page = 1
      this.getList()
    },
    async getShow() {
      try {
        const { data } = await sysService.getTerminalDrop()
        this.showData = data
      } catch (e) {}
    },
    onTypeChange(e) {
      if (this.showData.filter(item => item.id === e && item.is_release).length) {
        this.release = true
      } else {
        this.release = false
      }
    },
    async getLeft() {
      const { data } = await service.groupList()
      this.typeArr = [{ name: '全部关联类型', id: '' }].concat(data.fixed)
    },
    async getList() {
      try {
        const { data } = await service.getList({
          page: this.page,
          page_size: this.pageSize,
          group_id: this.current,
          ...this.search,
        })
        this.data = data.record
        this.total = data.total
      } catch (e) {}
    },
    async getPoc() {
      try {
        const { data } = await service.getPoc()
        this.pocArr = [{ name: '全部分组', id: '' }].concat(data)
      } catch (e) {}
    },
    async getContent() {
      try {
        const { data } = await service.getContent({
          no_page: 1,
        })
        this.contentArr = [{ name: '全部分组', id: '' }].concat(data)
      } catch (e) {}
    },
    async getGroup() {
      try {
        const { data } = await sService.getList({ no_page: 1 })
        this.groupArr = data
        this.searchGroupArr = [{ name: '全部场景', id: '' }].concat(data)
      } catch (e) {}
    },
    selectChagne(e, item) {
      if (e.target.checked) {
        this.selectedRowKeys.push(item.id)
      } else {
        this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1)
      }
    },
    onAdd() {
      this.visible.add = true
      this.title = '新建入口'
      this.id = null
      this.add = {
        name: null,
        content: null,
        group_id: this.current,
        scene_id: [],
        describe: null,
      }
    },
    onEdit(item) {
      this.title = '修改入口'
      this.id = item.id
      this.add = item
      this.visible.add = true
    },
    async onDelete(item) {
      try {
        await service.delete({
          id: item.id,
        })
        this.page = 1
        this.getList()
        this.$message.success('删除成功')
      } catch (e) {}
    },
    onAddContent() {
      this.visible.choose = true
    },
    async handleOk() {
      try {
        if (this.typeShow.includes(this.current)) {
          await service.save({
            ...this.add,
            content: JSON.stringify(this.add.content),
          })
        } else {
          await service.save({
            id: this.id,
            ...this.add,
            content: JSON.stringify(this.add.content),
          })
        }

        this.$message.success(this.id ? '修改成功' : '添加成功')
        this.handleCancel()
        this.getGroup()
        this.getList()
      } catch (e) {}
    },
    handleCancel() {
      this.visible.add = false
    },
    contentChange(data) {
      this.add.content = data
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    onMove(item) {
      this.visible.move = true
      this.move.id = item.id
      this.move.group_id = [item.group_id]
    },
    async onLoadSelect() {
      try {
        const { data } = await service.download({
          type: this.search.type,
          ids: this.selectedRowKeys,
        })
        window.open(data.download_url)
        this.selectedRowKeys = []
      } catch (e) {}
    },
    async onloadPage(item) {
      if (item) {
        try {
          const { data } = await service.download({
            type: this.search.type,
            ids: [item.id],
          })
          window.open(data.download_url)
        } catch (e) {}
        return
      }
      try {
        const { data } = await service.download({
          type: this.search.type,
          ids: this.data.map(item => item.id),
        })
        window.open(data.download_url)
        this.selectedRowKeys = []
      } catch (e) {}
    },
    onAddEnter(item) {
      this.visible.add = true
      this.add = {
        name: null,
        content: item.content,
        group_id: [],
        scene_id: [],
        describe: null,
      }
    },
    onCopy(item) {
      this.visible.copy = true
      this.copy.link = item.link_url
    },
    onCopyLink() {
      var Url2 = this.copy.link
      var oInput = document.createElement('input')
      oInput.value = Url2
      document.body.appendChild(oInput)
      oInput.select()
      document.execCommand('Copy')
      this.$message.success('复制成功')
      oInput.parentNode.removeChild(oInput) // 删一哈
    },
    async onMoveOk() {
      if (!this.move.group_id.length) {
        this.$message.error('请选择分组')
        return
      }
      try {
        await service.move(this.move)
        this.visible.move = false
        this.$message.success('移动成功')
        this.getList()
        this.move.group_id = []
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.modal {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .copy {
    line-height: 1 !important;
  }
}

.box {
  flex: 1;
  padding: 20px;
  background: #fff;

  .input {
    display: inline-block;
    width: 150px;

    .icon {
      color: #000;
      font-size: 16px;
    }
  }

  .view {
    width: 100%;

    :global {
      .ant-row-flex {
        width: 100%;
      }
    }

    .item {
      width: 100%;
      border: 1px solid #eee;

      .cover {
        position: relative;
        padding: 10px;

        img {
          width: 100%;
        }

        .select {
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
          height: 30px;
          padding-left: 4px;
          background: #eee;
          border-radius: 0 0 100px 0;
        }
      }

      .btn {
        padding: 0 10px;
      }

      .name {
        margin-bottom: 20px;
        color: #000;
        font-size: 14px;
      }

      .title {
        margin-bottom: 10px;
        color: #000;
        font-size: 14px;
      }

      .relation {
        margin-bottom: 6px;
        color: #5c5c5c;
        font-size: 12px;
      }

      .num {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
      }

      .tag {
        max-width: 80px;
        padding: 0 5px;
        color: #5c5c5c;
        font-size: 10px;
        background-color: #fafafa;
        border: 1px solid #eee;
        border-radius: 6px;
      }

      .btns {
        display: flex;
        justify-content: space-between;
        margin: 0 10px 20px;

        i {
          color: #000;
          font-size: 18px;
        }

        i:hover {
          color: @primary-color;
        }

        .error {
          color: @error-color;
        }
      }
    }
  }
}
</style>
