<template>
  <div :class="$style.group">
    <div :class="$style.title">
      <span>入口分组</span>
      <i
        v-permission="'trace_source.entrance.manage-group.create'"
        :class="$style.add"
        class="ri-add-circle-line"
        @click="onAdd()"
      />
    </div>
    <div :class="$style.box">
      <div
        v-for="item in data.fixed"
        :key="item.id"
        :class="[$style.item, current === item.id ? $style.active : null]"
        @click="onSelect(item)"
      >
        <i :class="item.icon" />
        <span>{{ item.name }}</span>
        <i :class="$style.fixed" class="ri-pushpin-line" />
      </div>
    </div>
    <div :class="$style.line" />
    <div :class="$style.custom">
      <div
        v-for="item in data.group"
        :key="item.id"
        :class="[$style.item, current === item.id ? $style.active : null]"
        @click="onSelect(item)"
      >
        <i :class="item.icon" />
        <span>{{ item.name }}</span>
        <a-dropdown placement="bottomRight">
          <i :class="$style.more" class="ri-more-fill primary" />
          <a-menu slot="overlay" class="list-drop">
            <a-menu-item>
              <BjLink
                v-permission="'trace_source.entrance.manage-group.edit'"
                :class="$style.drop"
                @click="onEdit(item)"
              >
                <i class="ri-edit-2-line" />
                修改分组
              </BjLink>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm title="确定要删除分组吗？" @confirm="onDelete(item)">
                <BjLink v-permission="'trace_source.entrance.manage-group.delete'" :class="$style.drop">
                  <i class="ri-delete-bin-7-line" />
                  删除分组
                </BjLink>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>

    <bj-modal
      :title="title"
      :visible="visible.add"
      :width="520"
      :body-style="{ height: '400px' }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <BjForm>
        <BjInput
          v-model="add.name"
          label="分组名称"
          v-bind="layout"
          label-align="left"
          rules="required"
          placeholder="请输入分组名称"
        />
        <a-row>
          <a-col span="5">描述信息</a-col>
          <a-col span="19">
            <a-textarea
              v-model="add.describe"
              placeholder="请输入此分组的描述信息"
              :auto-size="{ minRows: 6, maxRows: 6 }"
            />
          </a-col>
        </a-row>
      </BjForm>
    </bj-modal>
  </div>
</template>

<script>
import { entranceService } from '@/service'

const service = new entranceService()

export default {
  name: 'Home',
  data() {
    return {
      visible: {
        add: false,
        rename: false,
      },
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
      },
      data: {
        fixed: [],
        group: [],
      },
      add: {
        name: null,
        describe: null,
      },
      title: '添加分组',
      id: null,
      current: 'poc',
    }
  },
  created() {
    this.getGroup()
    this.$emit('change', 'poc')
  },
  methods: {
    onSelect(item) {
      this.current = item.id
      this.$emit('change', item.id)
    },
    async getGroup() {
      const { data } = await service.groupList()
      this.data = data
    },
    async handleOk() {
      try {
        this.id ? await service.groupEdit({ id: this.id, ...this.add }) : await service.groupAdd(this.add)
        this.$message.success(this.id ? '修改成功' : '添加成功')
        this.handleCancel()
        this.getGroup()
        this.$emit('refresh')
      } catch (e) {}
    },
    handleCancel() {
      this.visible.add = false
    },
    onAdd() {
      this.visible.add = true
      this.add = {
        name: null,
        describe: null,
      }
      this.title = '添加分组'
      this.id = null
    },
    onEdit(item) {
      this.visible.add = true
      this.title = '修改分组'
      this.add.name = item.name
      this.add.describe = item.describe
      this.id = item.id
    },
    async onDelete(item) {
      try {
        await service.groupDelete({
          id: item.id,
        })
        this.$message.success('删除成功')
        this.getGroup()
        this.$emit('refresh')
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.group {
  width: 200px;
  margin-right: 20px;
  padding: 20px;
  background: #fff;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .title {
    position: relative;
    margin-bottom: 10px;

    .add {
      position: absolute;
      top: -5px;
      right: 0;
      color: @primary-color;
      font-size: 20px;
    }
  }

  .custom {
    .item {
      display: flex;
      height: 44px;
      margin-bottom: 2px;
      padding: 0 10px 0 12px;
      line-height: 44px;
      border-radius: 6px;
      cursor: pointer;

      i {
        font-size: 20px;
      }

      span {
        flex: 1;
        margin: 0 8px;
      }
    }

    .item:hover {
      color: @primary-color;
      background: #fafafa;
    }

    .more {
      opacity: 0;
    }

    .item:hover .more {
      opacity: 1;
    }
  }

  .box {
    margin-top: 14px;
    /* stylelint-disable-next-line no-descending-specificity */
    .item {
      position: relative;
      display: flex;
      height: 44px;
      margin-bottom: 2px;
      padding-left: 12px;
      line-height: 44px;
      border-radius: 6px;
      cursor: pointer;

      i {
        margin-right: 8px;
        font-size: 20px;
      }

      .fixed {
        position: absolute;
        top: -8px;
        right: 4px;
        height: 16px;
        color: #bbb;
        font-size: 16px;
      }
    }

    .item:hover {
      color: @primary-color;
      background: #fafafa;
    }
  }

  .line {
    margin: 10px 0;
    border-top: 1px solid #eee;
  }

  .active {
    color: @primary-color;
    background: #fafafa;
  }
}
</style>
