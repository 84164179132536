<template>
  <div :class="$style.home">
    <div :class="$style.box">
      <div :class="$style.icon">
        <i class="ri-emotion-line" />
      </div>
      <div :class="$style.tip">
        <p :class="$style.title">入口管理</p>
        <p :class="$style.desc">
          入口管理是对文化主页，文化内容等访问入口进行管理，可以设置入口访问的场景，下载文化码和导出文化链接等。
        </p>
      </div>
    </div>

    <div :class="$style.view">
      <group @change="groupChange" @refresh="refresh" />
      <box ref="box" :current="current" />
    </div>
  </div>
</template>

<script>
import box from './box.vue'
import group from './group.vue'

export default {
  name: 'Home',
  components: {
    group,
    box,
  },
  data() {
    return {
      current: 'poc',
    }
  },
  methods: {
    groupChange(id) {
      this.current = id
    },
    refresh() {
      this.$refs.box.getLeftGroup()
    },
  },
}
</script>

<style lang="less" module>
.home {
  .box {
    display: flex;
    height: 90px;
    padding: 20px;
    background: #fff;

    .icon {
      width: 50px;
      height: 50px;
      margin-right: 12px;
      line-height: 50px;
      text-align: center;
      background: #fafafa;
      border-radius: 100%;

      i {
        color: #000;
        font-size: 20px;
      }
    }

    .tip {
      .title {
        margin-bottom: 4px;
        color: #000;
        font-size: 16px;
      }

      .desc {
        color: #5c5c5c;
        font-size: 12px;
      }
    }
  }

  .view {
    display: flex;
    margin-top: 20px;
  }
}
</style>
